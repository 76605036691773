var url = window.location.origin
export const BaseUrl =
    // url === "http://gayatriagroerp.gkmarket.in" ? "http://gayatriagroerpapi.gkmarket.in"

    url === "http://demogayatriagroerp.gkmarket.in" ? "http://demogayatriagroerpapi.gkmarket.in"
        : url === "http://gayatrierp.in" ? "http://api.gayatrierp.in"
            : url === "https://gayatrierp.in" ? "https://api.gayatrierp.in" :
                url === "https://fmcerp.cdat.work" ? "https://apifmcerp.cdat.work" :
                    url === "http://mis.psagroexports.com" ? "http://apimis.psagroexports.com" :
                        url === "https://mis.psagroexports.com" ? "https://apimis.psagroexports.com" :
                         url === "http://mis.palviagro.com" ? "http://apimis.palviagro.com" :
                         url === "https://mis.palviagro.com" ? "https://apimis.palviagro.com" :
                            "http://apimis.palviagro.com"
                            // "https://apifmcerp.cdat.work"

// "http://demogayatriagroerpapi.gkmarket.in"
// "https://api.gayatrierp.in"


// "http://gayatriagroerpapi.gkmarket.in"


